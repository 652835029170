$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordarkgrey: #706768;
$colordark: #353353;
$colordarkpink: #E0A6AA;
$colordarkerpink: #b4888a;
$colorlightpink: #fcedef;
$coloryellow: #ffd15c;
$colorpink: #ff4c60;
$colorblue: #6c6ce5;
$colordefault: #454360;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
  -moz-transition: all $second ease-in-out;
  transition: all $second ease-in-out;
}
